import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

const ContactModal = ({ changeModal, changeContactModal, prodValue }) => {
  // Input values
  const [nameValue, setNameValue] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // To show success message after submission

  // Invalid input states
  const [invalidName, setInvalidName] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);

  // Default country code
  const countryCode = '+998'; // Default country code for Uzbekistan

  const handleNumberChange = (value) => {
    // Ensure only numeric input by stripping non-numeric characters
    const numericValue = value.replace(/\D/g, ''); 
    setNumberValue(numericValue);
    setInvalidNumber(false);
  };

  const handleNameChange = (value) => {
    setNameValue(value);
    setInvalidName(false);
  };

  // Send message to Telegram bot
  const sendMessage = () => {
    if (nameValue === '') {
      setInvalidName(true);
    } else if (numberValue === '' || numberValue.length < 9) { // Ensure phone number is at least 9 digits long
      setInvalidNumber(true);
    } else {
      const botToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
      const chatID = process.env.REACT_APP_TELEGRAM_CHAT_ID;
      const message = `Ismi: ${nameValue};\nTelefon raqami: ${countryCode} ${numberValue};\nXarid qilmoqchi bo'lgan mahsulot nomi: "${prodValue}"`;

      fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatID,
          text: message,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setSuccessMessage('Your message was successfully sent!'); // Success message
          } else {
            console.error('Error sending message');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      // Clear the form and reset states
      setNameValue('');
      setNumberValue('');
      setInvalidName(false);
      setInvalidNumber(false);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4 relative">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            onClick={changeContactModal}
          >
            <MdClose size={24} />
          </button>

          {/* Form */}
          <h2 className="text-xl font-bold text-gray-800 mb-4">{t('contactUs')}</h2>

          {successMessage && (
            <p className="text-green-500 mb-4">{successMessage}</p>
          )}

          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 mb-2">
              {t('name')}
            </label>
            <input
              type="text"
              id="name"
              className={`w-full px-3 py-2 border ${
                invalidName ? 'border-red-500' : 'border-gray-300'
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
              value={nameValue}
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder={t('enterYourName')}
            />
            {invalidName && (
              <p className="text-red-500 text-sm mt-1">{t('nameRequired')}</p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="phone" className="block text-gray-700 mb-2">
              {t('phone')}
            </label>
            <div className="flex items-center">
              <div className="px-3 py-2 border border-gray-300 bg-gray-100 rounded-l-lg">
                {countryCode}
              </div>
              <input
                type="tel"
                id="phone"
                className={`w-full px-3 py-2 border ${
                  invalidNumber ? 'border-red-500' : 'border-gray-300'
                } rounded-r-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                value={numberValue}
                onChange={(e) => handleNumberChange(e.target.value)}
                inputMode="numeric" // Ensures numeric keyboard is shown on mobile
                pattern="[0-9]*" // Restricts input to numbers only
                placeholder="99-999-9999"
              />
            </div>
            {invalidNumber && (
              <p className="text-red-500 text-sm mt-1">{t('phoneRequired')}</p>
            )}
          </div>

          <button
            className="w-full bg-[#019EDC] text-white font-semibold py-2 px-4 rounded hover:bg-[#017bb2] transition-colors duration-300"
            onClick={sendMessage}
          >
            {t('send')}
          </button>
        </div>
      </div>
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={changeContactModal}
      ></div>
    </>
  );
};

export default ContactModal;
