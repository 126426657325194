import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchCategories } from '../api/Api';
import { getName } from '../languages/language';
import {
  FaYoutube,
  FaFacebookF,
  FaTelegramPlane,
  FaInstagram,
} from 'react-icons/fa';
import { MdLocationOn, MdEmail } from 'react-icons/md';

const Footer = () => {
  // i18next
  const { t } = useTranslation();

  // API options
  const { data: categoriesData } = useQuery('categories', fetchCategories);

  // Brand colors
  const brandColor = '#019EDC'; // Your brand color
  const brandDarker = '#017bb2'; // Darker version for hover effect

  return (
    <footer className="bg-[#017bb2] mt-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-bold text-white mb-4">{t('Clemar')}</h2>
            <p className="text-gray-200 mb-2">{t('contacts')}</p>
            <a
              href="tel:+998990081411"
              className="text-white hover:text-gray-300 mb-2 block"
            >
              +998 99 008 14 11
            </a>
            <div className="flex items-start mb-2">
              <MdLocationOn className="text-white mt-1 mr-2" />
              <a
                href="https://yandex.ru/navi/?ll=69.220719,41.290161&panorama%5Bpoint%5D=69.220719,41.290161&panorama%5Bdirection%5D=227.914276,-0.865454&panorama%5Bspan%5D=90.000000,90.000000&panorama%5Bid%5D=1486659497_804360267_23_1571374783"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-200 hover:text-white"
              >
                {t('dokon')}
              </a>
            </div>
            <div className="flex items-start mb-4">
              <MdEmail className="text-white mt-1 mr-2" />
              <a
                href="mailto:Info@cleanmarket.uz"
                className="text-gray-200 hover:text-white"
              >
                Info@cleanmarket.uz
              </a>
            </div>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/cleanmarket.uz"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300"
              >
                <FaFacebookF size={24} />
              </a>
              <a
                href="https://youtube.com/@cleanmarket_uz"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300"
              >
                <FaYoutube size={24} />
              </a>
              <a
                href="https://t.me/cleaning_market"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300"
              >
                <FaTelegramPlane size={24} />
              </a>
            </div>
          </div>

          {/* Products Links */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">{t('productss')}</h3>
            <ul className="space-y-2">
              {categoriesData?.data?.slice(0, 4)?.map((item) => (
                <li key={item.id}>
                  <Link
                    to={`/categories/${item.id}`}
                    className="text-gray-200 hover:text-white"
                  >
                    {getName(item)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Information Links */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">{t('infooo')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-gray-200 hover:text-white">
                  {t('about')}
                </Link>
              </li>
              <li>
                <Link to="/categories" className="text-gray-200 hover:text-white">
                  {t('kategoriyalar')}
                </Link>
              </li>
              <li>
                <Link to="/why-us" className="text-gray-200 hover:text-white">
                  {t('negabiz')}
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-gray-200 hover:text-white">
                  {t('faq')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Location and Real Map */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">{t('footer_loc1')}</h3>
            <p className="text-gray-200 mb-2 flex items-center">
              {t('footer_loc2')}
            </p>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A2f539e8e6b2b4db6847cd78ffab05d7b62666c4a2c05ae0c1d5d2315e9228be2&source=constructor"
              width="100%"
              height="200"
              frameBorder="0"
              allowFullScreen
              title="Yandex Map"
              className="rounded-lg shadow-lg"
            ></iframe>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-400 mt-8 pt-6 text-center">
          <p className="text-gray-200">
            © {new Date().getFullYear()} CleanMarket. {t('allRightsReserved')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
