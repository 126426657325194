// Modal.js
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Modal = ({ changeModal, productInfo }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  const BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phone.length < 10) {
      setError(t('Please enter a valid phone number.'));
      return;
    }

    // Prepare the message to send to Telegram
    const message = `New order request:\nProduct: ${productInfo.name}\nPhone: ${phone}`;

    try {
      // Send the message to Telegram
      await axios.post(
        `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
        {
          chat_id: CHAT_ID,
          text: message,
        }
      );

      // Close the modal after successful submission
      changeModal();
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
      setError(t('An error occurred. Please try again later.'));
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm mx-4">
          <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            {t('Enter Your Phone Number')}
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              {/* Phone Input with Flag */}
              <PhoneInput
                country={'uz'} // Set default country to Uzbekistan
                value={phone}
                onChange={setPhone}
                inputClass="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                inputStyle={{ width: '100%' }}
                placeholder={t('Phone Number')}
                specialLabel=""
              />
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
            <button
              type="submit"
              className="w-full bg-[#019EDC] text-white font-semibold py-2 px-4 rounded hover:bg-[#0180B2] transition-colors duration-300"
            >
              {t('Submit')}
            </button>
          </form>
        </div>
      </div>
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black opacity-50 z-40"
        onClick={() => changeModal()}
      ></div>
    </>
  );
};

export default Modal;
