import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Infographics = () => {
  // Initialize translation
  const { t } = useTranslation();

  // Initialize AOS (Animate On Scroll)
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);

  return (
    <div className="py-16 bg-gray-50">
      <div className="container mx-auto px-6 sm:px-8 lg:px-12">

        {/* Infographic Items */}
        <div className="flex flex-wrap justify-between mb-12">
          <div
            className="text-center transform hover:scale-105 transition duration-500 p-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2 className="text-6xl font-extrabold text-[#019EDC] mb-4">40K</h2>
            <p className="text-xl text-gray-700">{t("inf1")}</p>
          </div>
          <div
            className="text-     transform hover:scale-105 transition duration-500 p-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h2 className="text-6xl font-extrabold text-[#019EDC] mb-4">10+</h2>
            <p className="text-xl text-gray-700">{t("inf2")}</p>
          </div>
          <div
            className="text-center transform hover:scale-105 transition duration-500 p-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h2 className="text-6xl font-extrabold text-[#019EDC] mb-4">40K</h2>
            <p className="text-xl text-gray-700">{t("inf3")}</p>
          </div>
          <div
            className="text-center transform hover:scale-105 transition duration-500 p-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h2 className="text-6xl font-extrabold text-[#019EDC] mb-4">40K</h2>
            <p className="text-xl text-gray-700">{t("inf4")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infographics;
