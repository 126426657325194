import React, { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsSaved } from '../../redux/actions/planActions';
import { useInView } from 'react-intersection-observer'; // Import the hook

// Fetch products with pagination support
const fetchProducts = async ({ pageParam = 1 }) => {
  console.log(`Fetching page ${pageParam}`);
  const response = await fetch(`https://clean.maxone.uz/api/products/?page=${pageParam}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  console.log('Fetched data:', data);
  return data; // Ensure it returns the correct data structure for react-query pagination
};

const ProductsList = () => {
  const dispatch = useDispatch();
  const savedProducts = useSelector((state) => state.savedProducts.products);

  const { t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng') || 'en';

  // Save or unsave product
  const saveProduct = (itemId) => {
    if (savedProducts.includes(itemId)) {
      dispatch(addProductsSaved(savedProducts.filter((c) => c !== itemId)));
    } else {
      dispatch(addProductsSaved([...savedProducts, itemId]));
    }
  };

  // Infinite Query setup using react-query
  const {
    data, // This will include all pages
    isLoading,
    error,
    fetchNextPage, // Function to fetch the next page
    hasNextPage, // Boolean to check if there's a next page
    isFetchingNextPage, // Boolean indicating if we are loading the next page
  } = useInfiniteQuery('products', fetchProducts, {
    getNextPageParam: (lastPage) => {
      console.log('lastPage:', lastPage);
      if (lastPage.nextPage) return lastPage.nextPage;
      return false; // No more pages
    },
  });

  // Use the useInView hook from react-intersection-observer for infinite scroll
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger the fetch when 50% of the loader is visible
  });

  // When inView becomes true, we load more products
  useEffect(() => {
    if (inView && hasNextPage) {
      console.log('Bottom in view, fetching next page...');
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // Handle loading and error states
  if (isLoading) return <p>{t('loading')}...</p>;
  if (error) return <p>{t('errorLoadingProducts')}...</p>;

  // Flatten the paginated results
  const products = data?.pages.flatMap((page) => page.results) || [];

  console.log('All products:', products);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold mb-8 text-gray-800">{t('All Products')}</h1>

      {products.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {products.map((item) => (
            <div
              key={item?.id}
              className="group flex flex-col bg-white rounded-lg border border-gray-200 p-6 hover:shadow-lg transition-shadow duration-300"
            >
              <div className="relative">
                {item?.new && (
                  <div className="absolute top-2 left-2 bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded">
                    {t('new')}
                  </div>
                )}
                <button
                  className="absolute top-2 right-2 bg-white p-2 rounded-full"
                  onClick={() => saveProduct(item?.id)}
                >
                  {savedProducts.includes(item?.id) ? (
                    <MdFavorite className="text-red-500 w-8 h-8" />
                  ) : (
                    <MdFavoriteBorder className="text-gray-600 w-8 h-8" />
                  )}
                </button>
                <Link to={`/products/${item?.slug}`}>
                  <img
                    src={'https://clean.maxone.uz' + item?.image1}
                    alt={item?.name_en}
                    className="w-full h-56 object-contain"
                  />
                </Link>
              </div>
              <div className="mt-4 flex-1">
                <p className="text-gray-700 font-medium text-sm line-clamp-1">
                  {lang === 'uz' ? item?.name_uz : lang === 'ru' ? item?.name_ru : item?.name_en}
                </p>
                <p className="text-lg font-semibold text-gray-800 mt-1">
                  {item?.price} {t('sum')}
                </p>
              </div>
              <button
                className="mt-4 bg-[#019EDC] bg-opacity-80 text-white font-semibold py-3 px-6 rounded hover:bg-opacity-100 transition-colors duration-300"
              >
                {t('buy')}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>{t('noProductsFound')}</p>
      )}

      {/* Infinite Scroll Loader */}
      <div ref={ref}>
        {isFetchingNextPage && <p>{t('loadingMoreProducts')}...</p>}
      </div>
    </div>
  );
};

export default ProductsList;
