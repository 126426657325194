import React from 'react';
// Import SVG icons
import OfficialDealer from '../../assets/icons/OfficialDealer.svg';
import FreeDemo from '../../assets/icons/FreeDemo.svg';
import Service from '../../assets/icons/Service.svg';
import Training from '../../assets/icons/Training.svg';
import { useTranslation } from 'react-i18next';  // Import the translation hook

const FourReasons = () => {
  const { t } = useTranslation();  // Get the translation function from i18next

  const reasons = [
    {
      icon: <img src={OfficialDealer} alt={t('official_dealer')} className="w-16 h-16 mb-4" style={{ filter: 'invert(25%) sepia(91%) saturate(478%) hue-rotate(176deg) brightness(96%) contrast(92%)' }} />,
      title: t('official_dealer'),
      description: t('description_official_dealer'),
    },
    {
      icon: <img src={FreeDemo} alt={t('free_demo')} className="w-16 h-16 mb-4" style={{ filter: 'invert(25%) sepia(91%) saturate(478%) hue-rotate(176deg) brightness(96%) contrast(92%)' }} />,
      title: t('free_demo'),
      description: t('description_free_demo'),
    },
    {
      icon: <img src={Service} alt={t('service')} className="w-16 h-16 mb-4" style={{ filter: 'invert(25%) sepia(91%) saturate(478%) hue-rotate(176deg) brightness(96%) contrast(92%)' }} />,
      title: t('service'),
      description: t('description_service'),
    },
    {
      icon: <img src={Training} alt={t('training')} className="w-16 h-16 mb-4" style={{ filter: 'invert(25%) sepia(91%) saturate(478%) hue-rotate(176deg) brightness(96%) contrast(92%)' }} />,
      title: t('training'),
      description: t('description_training'),
    },
  ];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header */}
      <h2 className="text-3xl sm:text-4xl font-bold text-left mb-12 text-[#000000]">
        {t('why_work_with_us')} {/* Translated header */}
      </h2>

      {/* Grid for reasons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {reasons.map((reason, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-[#f7f7f7] rounded-lg p-6 text-center border border-gray-200"
          >
            {/* Icon */}
            <div className="flex justify-center w-full mb-4">
              {reason.icon}
            </div>
            {/* Title */}
            <h3 className="text-lg font-bold mb-2 text-center text-[#000000]">{reason.title}</h3>
            {/* Description */}
            <p className="text-gray-600 text-sm text-center">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FourReasons;
