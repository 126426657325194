import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchCategories } from '../../api/Api';
import { getName } from '../../languages/language';

const HomeCategories = () => {
  // Fetch categories using React Query
  const { data: categoriesData, isLoading, error } = useQuery('categories', fetchCategories);

  // i18next for translation
  const { t } = useTranslation();

  // Debugging: Log the categories data to check its structure
  useEffect(() => {
    if (categoriesData) {
      console.log('Categories Data:', categoriesData);
    }
  }, [categoriesData]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-xl text-gray-500">{t('loading')}...</div>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-xl text-red-500">{t('errorLoadingCategories')}...</div>
      </div>
    );
  }

  // Check if categories data is empty
  if (!categoriesData?.data || categoriesData.data.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-xl text-gray-500">{t('noCategoriesFound')}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col gap-8">
        {/* Header */}
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-0">
            {t('kategoriyalar')}
          </h1>
          <Link
            to="/categories"
            className="text-[#019EDC] hover:text-blue-600 underline focus:outline-none focus:ring-2 focus:ring-blue-500 rounded"
          >
            {t('barchasi')}
          </Link>
        </div>

        {/* Categories Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {categoriesData.data.map((item) => (
            <Link
              key={item.id}
              to={`/categories/${item.id}`}
              className="group flex flex-col items-center p-6 bg-white rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300 focus:outline-none focus:ring-2 focus:ring-[#019EDC]"
            >
              {/* Category Image */}
              <div className="w-full h-32 flex items-center justify-center mb-4">
                <img
                  src={item.image || '/default-category.png'} // Fallback image if category image is missing
                  alt={getName(item)}
                  className="max-h-full object-contain"
                  loading="lazy" // Improves performance by lazy loading images
                />
              </div>
              {/* Category Name */}
              <p className="text-base sm:text-lg md:text-xl font-semibold text-gray-800 text-center group-hover:text-[#019EDC] transition-colors duration-300">
                {getName(item)}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCategories;
