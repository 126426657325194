import React from 'react';
import { useQuery } from 'react-query';
import { fetchPartner } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';  // For the 'view all brands' link

const Partners = () => {

    // API options
    const dataPartners = useQuery('partners', fetchPartner);

    // i18next
    const { t } = useTranslation();

    return (
        <div className="py-12 bg-white">
            <div className="container mx-auto px-8">  {/* Added px-8 for 2rem padding */}
                {/* Header */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">{t("our_brands")}</h1>
                </div>
                
                {/* Grid Layout for Partner Logos */}
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                    {dataPartners?.data?.data?.map((item) => (
                        <div key={item?.id} className="flex justify-center items-center bg-white p-6 border border-gray-200 rounded-lg shadow-sm">
                            <img src={item?.image} alt={item?.name} className="h-16 w-full object-contain" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Partners;
