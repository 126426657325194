import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchTopProducts } from '../../api/Api';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'; // Use Material Design Icons for heart
import { useDispatch, useSelector } from 'react-redux';
import { addProductsSaved } from '../../redux/actions/planActions';

const TopProducts = ({ changeProdValue }) => {
  // Redux options
  const dispatch = useDispatch();
  const savedProducts = useSelector((state) => state.savedProducts.products);

  const saveProduct = (itemId) => {
    if (savedProducts.includes(itemId)) {
      dispatch(addProductsSaved(savedProducts.filter((c) => c !== itemId)));
    } else {
      dispatch(addProductsSaved([...savedProducts, itemId]));
    }
  };

  // API options
  const { data: topProductsData, isLoading, error } = useQuery('top-products', fetchTopProducts);

  // i18next
  const { t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng') || 'en';

  // Debugging: Log the top products data to check its structure
  console.log('Top Products Data:', topProductsData);

  // Handling possible errors or loading state
  if (isLoading) return <p>{t("loading")}...</p>;
  if (error) return <p>{t("errorLoadingProducts")}...</p>;

  // Extract products array from API response
  let products = [];
  if (Array.isArray(topProductsData?.data)) {
    products = topProductsData.data;
  } else if (Array.isArray(topProductsData?.results)) {
    products = topProductsData.results;
  } else if (Array.isArray(topProductsData)) {
    products = topProductsData;
  } else {
    products = [];
  }

  // Limit products to show only 2 rows
  const productsToShow = products.slice(0, 8); // Show 8 products for desktop (4 per row x 2 rows)

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">{t("top")}</h1>
        <Link
          to="/products"
          className="text-sm sm:text-lg text-[#019EDC] hover:text-blue-600 underline"
        >
          {t("barchasi")}
        </Link>
      </div>

      {/* Products Grid */}
      {productsToShow.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {productsToShow.map((item) => (
            <div
              key={item?.id}
              className="group flex flex-col bg-white rounded-lg border border-gray-200 p-6 hover:shadow-lg transition-shadow duration-300"
            >
              {/* Product Image */}
              <div className="relative">
                {/* Badges */}
                {item?.new && (
                  <div className="absolute top-2 left-2 bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded">
                    {t("new")}
                  </div>
                )}
                {item?.percent && (
                  <div className="absolute top-2 left-2 bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded">
                    {item?.percent}
                  </div>
                )}
                {item?.recommend && (
                  <div className="absolute top-2 left-2 bg-white text-gray-800 text-xs font-semibold px-2 py-1 rounded">
                    {t("recommend")}
                  </div>
                )}
                {/* Favorite Button */}
                <button
                  className="absolute top-2 right-2 bg-white p-2 rounded-full"
                  onClick={() => saveProduct(item?.id)}
                >
                  {savedProducts.includes(item?.id) ? (
                    <MdFavorite className="text-red-500 w-8 h-8" />
                  ) : (
                    <MdFavoriteBorder className="text-gray-600 w-8 h-8" />
                  )}
                </button>
                <Link to={`/products/${item?.slug}`}>
                  <img
                    src={item?.image1}
                    alt={item?.name_en}
                    className="w-full h-56 object-contain"
                  />
                </Link>
              </div>
              {/* Product Name */}
              <div className="mt-4 flex-1">
                <p className="text-gray-700 font-medium text-sm line-clamp-1">
                  {lang === 'uz'
                    ? item?.name_uz
                    : lang === 'ru'
                    ? item?.name_ru
                    : item?.name_en}
                </p>
                {/* Price */}
                <p className="text-lg font-semibold text-gray-800 mt-1">
                  {item?.price} {t("sum")}
                </p>
              </div>
              {/* Buy Button */}
              <button
                className="mt-4 bg-[#019EDC] bg-opacity-80 text-white font-semibold py-3 px-6 rounded hover:bg-opacity-100 transition-colors duration-300"
                onClick={() => changeProdValue(item?.name_uz)}
              >
                {t("buy")}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p>{t("noProductsFound")}</p>
      )}
    </div>
  );
};

export default TopProducts;
