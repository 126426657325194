import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchFaq } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import { getAnswer, getQuestion } from '../../languages/language';

const FAQ = () => {
  // Fetch FAQ data using react-query
  const { data: faqData, isLoading, error } = useQuery('FAQ', fetchFaq);

  // Initialize translation
  const { t } = useTranslation();

  // Handle loading and error states
  if (isLoading) {
    return <div className="text-center py-8">{t("loading")}</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{t("error_loading_faq")}</div>;
  }

  return (
    <div className="flex justify-center py-8">
      <div className="w-full max-w-3xl px-4">
        <h1 className="text-3xl font-bold text-center mb-8">{t("faq")}</h1>
        <div className="space-y-4">
          {faqData?.data?.map((item) => (
            <FaqItem key={item?.id} question={getQuestion(item)} answer={getAnswer(item)} />
          ))}
        </div>
      </div>
    </div>
  );
};

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="border-b border-gray-300 pb-4 cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold">{question}</p>
        <span className="text-xl">
          {isOpen ? '-' : '+'}
        </span>
      </div>
      {isOpen && <p className="text-gray-700 mt-2">{answer}</p>}
    </div>
  );
};

export default FAQ;
