// Navbar.js
import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLang, getName } from '../languages/language';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchCategories, fetchProducts, fetchSubCategories } from '../api/Api';

// Material UI Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import Logo from '../assets/images/logo.png';

const Navbar = ({ changeProdValue }) => {
  const path = useLocation();
  const navigate = useNavigate();

  // Language options
  const { i18n, t } = useTranslation();
  const [showLanguage, setShowLanguage] = useState(false);
  const languages = ['en', 'uz', 'ru']; // Define available languages
  const langTitle = i18n.language || 'ru'; // Default to 'ru' if not set

  const languageDropdownRef = useRef(null);

  // Close language dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showLanguage &&
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setShowLanguage(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showLanguage]);

  function changeLangValue(item) {
    changeLang(item);
    setShowLanguage(false);
    i18n.changeLanguage(item);
  }

  // Dropdown menu state for mobile view
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [isCatalogOpen, setCatalogOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Search options
  const [showSearch, setShowSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [dataProducts, setDataProducts] = useState([]);

  // Debounce the input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // Fetch products when debounced input value changes
  useEffect(() => {
    if (debouncedInputValue) {
      // Updated fetchProducts call to pass an object with search params
      fetchProducts({ search: debouncedInputValue })
        .then((response) => {
          setDataProducts(response.data.results || []);
          setShowSearch(true);
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
          setDataProducts([]);
          setShowSearch(false);
        });
    } else {
      setDataProducts([]);
      setShowSearch(false);
    }
  }, [debouncedInputValue]);

  function handleSearch(item) {
    setInputValue(item);
  }

  const dataCategories = useQuery('categories', fetchCategories);
  const dataSubCategories = useQuery('sub-categories', fetchSubCategories);

  // Desired order based on category IDs
  const desiredOrder = [17, 19, 16, 18, 15];

  // Catalog options
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (!dataCategories.isLoading && dataCategories.data?.data) {
      defaultCategory();
    }
  }, [dataCategories.isLoading, dataCategories.data]);

  function defaultCategory() {
    const sortedCategories = getSortedCategories();
    if (sortedCategories.length > 0) {
      setCategory(sortedCategories[0].id);
    }
  }

  const toggleSubCategories = (categoryId) => {
    if (expandedCategory === categoryId) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryId);
    }
  };

  // Redux options
  let savedProducts = useSelector(
    (state) => state.savedProducts?.products || []
  );
  let ordersCount = useSelector((state) => state.orders?.count || 0);
  let cartItemsCount = useSelector((state) => state.cart?.items?.length || 0);

  console.log('Navbar Component - Orders Count:', ordersCount);

  // Scroll options
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowSearch(false);
      setShowLanguage(false);
      setScroll(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to sort categories based on desiredOrder
  const getSortedCategories = () => {
    if (!dataCategories.data?.data) return [];

    const categories = [...dataCategories.data.data]; // Clone to avoid mutating original data

    // Sort categories based on the desiredOrder array
    categories.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.id);
      const indexB = desiredOrder.indexOf(b.id);

      if (indexA === -1 && indexB === -1) {
        // If both categories are not in desiredOrder, sort alphabetically by name_ru
        return a.name_ru.localeCompare(b.name_ru);
      } else if (indexA === -1) {
        // Categories not in desiredOrder come after those that are
        return 1;
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });

    return categories;
  };

  return (
    <nav className="relative fixed left-0 w-full z-50 bg-white border border-[#E5E7EB]">
      {/* Topbar */}
      <div className="bg-[#019EDC] text-white py-2 px-[6vw] flex justify-between items-center">
        {/* Left side - Location */}
        <div className="flex items-center space-x-2">
          <LocationOnIcon />
          <span>{t('city_name')}</span>
        </div>
        {/* Right side - Language Switcher and Social Icons */}
        <div className="flex items-center space-x-4">
          {/* Language Switcher */}
          <div className="relative" ref={languageDropdownRef}>
            <button
              onClick={() => setShowLanguage(!showLanguage)}
              className="flex items-center uppercase"
            >
              {langTitle.toUpperCase()}
              <ExpandMoreIcon />
            </button>
            {showLanguage && (
              <div className="absolute right-0 mt-2 bg-white text-black shadow-lg z-40">
                {languages.map((lang) => (
                  <button
                    key={lang}
                    onClick={() => changeLangValue(lang)}
                    className="block px-4 py-2 hover:bg-gray-100 uppercase"
                  >
                    {lang.toUpperCase()}
                  </button>
                ))}
              </div>
            )}
          </div>
          {/* Social Icons */}
          <div className="flex items-center space-x-2">
            <a href="#" className="text-white hover:text-gray-300">
              <InstagramIcon />
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              <TelegramIcon />
            </a>
            <a href="#" className="text-white hover:text-gray-300">
              <YouTubeIcon />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Navbar */}
      <div className="relative">
        {/* Mobile layout */}
        <div className="flex items-center justify-between py-2 px-[6vw] md:hidden">
          {/* Logo */}
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={Logo} alt="Logo" className="w-36 h-auto mt-2 mb-2" />
          </Link>
          {/* Right side icons */}
          <div className="flex items-center">
            {/* Burger Icon */}
            <MenuIcon
              className="text-3xl ml-4 cursor-pointer"
              onClick={() => setIsMobileMenuOpen(true)}
            />
          </div>
        </div>

        {/* Search Bar for Mobile */}
        <div className="px-[6vw] md:hidden mt-2 mb-2">
          <div className="relative w-full">
            <input
              type="text"
              placeholder={t('search_placeholder')}
              value={inputValue}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 pl-4 pr-10 border rounded"
            />
            <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            {showSearch && (
              <>
                <div className="absolute mt-2 w-full bg-white shadow-lg max-h-80 overflow-auto z-40">
                  {dataProducts.map((item) => (
                    <Link
                      key={item?.id}
                      to={`/products/${item?.slug}`}
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                      onClick={() => setShowSearch(false)}
                    >
                      {getName(item, 'ru')}
                    </Link>
                  ))}
                </div>
                <div
                  className="fixed inset-0"
                  onClick={() => setShowSearch(false)}
                ></div>
              </>
            )}
          </div>
        </div>

        {/* Desktop layout */}
        <div className="hidden md:flex items-center justify-between py-2 md:py-4 px-[6vw]">
          {/* Left Section */}
          <div className="flex items-center">
            {/* Logo */}
            <Link
              to="/"
              onClick={() => window.scrollTo(0, 0)}
              className="md:mr-4"
            >
              <img src={Logo} alt="Logo" className="w-36 h-auto mt-2 mb-2" />
            </Link>
            {/* Catalog Button */}
            <button
              className="flex items-center gap-1 font-bold text-base ml-4"
              onClick={() => setCatalogOpen(!isCatalogOpen)}
            >
              <MenuIcon />
              <p>{t('cat')}</p>
              <ExpandMoreIcon style={{ fontSize: '16px' }} />
            </button>
          </div>

          {/* Middle Section */}
          <div className="flex-grow mx-4">
            {/* Search Bar */}
            <div className="relative w-full">
              <input
                type="text"
                placeholder={t('search_placeholder')}
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                className="w-full p-2 pl-4 pr-10 border rounded focus:outline-none"
              />
              <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              {showSearch && (
                <>
                  <div className="absolute mt-2 w-full bg-white shadow-lg max-h-120 overflow-auto z-40">
                    {dataProducts.map((item) => (
                      <Link
                        key={item?.id}
                        to={`/products/${item?.slug}`}
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                        onClick={() => setShowSearch(false)}
                      >
                        {getName(item, 'ru')}
                      </Link>
                    ))}
                  </div>
                  <div
                    className="fixed inset-0"
                    onClick={() => setShowSearch(false)}
                  ></div>
                </>
              )}
            </div>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-6">
            {/* Orders Icon */}
            <Link to="/orders" className="relative">
              <ReceiptIcon className="text-gray-700" />
              <span className="absolute -top-2 -right-2 bg-[#019EDC] text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                {ordersCount}
              </span>
            </Link>

            {/* Wishlist Icon */}
            <Link to="/likes" className="relative">
              <FavoriteIcon className="text-gray-700" />
              <span className="absolute -top-2 -right-2 bg-[#019EDC] text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                {savedProducts?.length || 0}
              </span>
            </Link>

            {/* Shopping Cart Icon */}
            <Link to="/cart" className="relative">
              <ShoppingCartIcon className="text-gray-700" />
              <span className="absolute -top-2 -right-2 bg-[#019EDC] text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                {cartItemsCount}
              </span>
            </Link>
          </div>
        </div>

        {/* Mobile Catalog Sliding Panel */}
        {isMobileMenuOpen && (
          <>
            <div
              className="fixed inset-0 z-50 bg-black bg-opacity-50"
              onClick={() => setIsMobileMenuOpen(false)}
            ></div>
            <div
              className={`fixed left-0 top-0 h-full w-3/4 bg-white z-50 transform transition-transform duration-300 ${
                isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
              }`}
            >
              <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-bold">{t('catalog')}</span>
                  <CloseIcon onClick={() => setIsMobileMenuOpen(false)} />
                </div>
                {getSortedCategories().map((categoryItem) => (
                  <div key={categoryItem.id}>
                    <div
                      className="flex justify-between items-center py-2 cursor-pointer"
                      onClick={() => toggleSubCategories(categoryItem.id)}
                    >
                      <span className="font-semibold">
                        {getName(categoryItem, 'ru')}
                      </span>
                      <ExpandMoreIcon
                        className={`transition-transform ${
                          expandedCategory === categoryItem.id ? 'rotate-180' : ''
                        }`}
                      />
                    </div>
                    {expandedCategory === categoryItem.id && (
                      <div className="pl-4">
                        {dataSubCategories?.data?.data
                          ?.filter((sub) => sub.category === categoryItem.id)
                          ?.map((sub) => (
                            <Link
                              key={sub.id}
                              to={`/sub-categories/${sub.id}`}
                              className="block py-2 text-gray-700"
                              onClick={() => {
                                setIsMobileMenuOpen(false);
                                setExpandedCategory(null);
                              }}
                            >
                              {getName(sub, 'ru')}
                            </Link>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* Catalog Menu for Desktop */}
        {isCatalogOpen && (
          <>
            <div className="hidden md:block absolute left-0 top-full bg-white shadow-lg w-full px-[10vw] z-40">
              <div className="flex">
                {/* Categories */}
                <div className="w-1/3 overflow-auto max-h-80 border-r">
                  {getSortedCategories().map((item) => (
                    <div
                      key={item.id}
                      className={`flex items-center justify-between px-4 py-2 cursor-pointer ${
                        category === item.id ? 'bg-gray-200' : ''
                      }`}
                      onMouseEnter={() => setCategory(item.id)}
                      onClick={() => setCategory(item.id)}
                    >
                      <div className="flex items-center gap-2">
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt="icon"
                            className="w-7 h-7"
                          />
                        )}
                        <span className="font-semibold">
                          {getName(item, 'ru')}
                        </span>
                      </div>
                      <ExpandMoreIcon className="transform -rotate-90" />
                    </div>
                  ))}
                </div>
                {/* Subcategories */}
                <div className="w-2/3 p-4 overflow-auto max-h-80">
                  {dataSubCategories?.data?.data
                    ?.filter((c) => c.category === category)
                    .map((c) => (
                      <div key={c.id} className="mb-4">
                        <Link
                          to={`/sub-categories/${c.id}`}
                          className="text-lg font-semibold mb-2 block"
                          onClick={() => {
                            setCatalogOpen(false);
                            setCategory('');
                          }}
                        >
                          {getName(c, 'ru')}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="hidden md:block fixed inset-0 z-30"
              onClick={() => {
                setCatalogOpen(false);
                setCategory('');
              }}
            ></div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
