import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import TopProducts from '../home/TopProducts';
import { useTranslation } from 'react-i18next';
import { fetchProductItem } from '../../api/Api';

// Modal for full-width image display with navigation
const ImageModal = ({ imgSrc, onClose, imageList, currentIndex, setCurrentIndex }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
    <div className="relative max-w-full max-h-full">
      <img
        src={imgSrc}
        alt="Full-size product"
        className="object-contain w-full h-full max-h-screen max-w-screen"
      />
      <button
        onClick={onClose}
        className="absolute top-2 right-2 bg-white text-gray-800 p-2 rounded-full focus:outline-none"
      >
        ✕
      </button>
      {/* Left Arrow */}
      {currentIndex > 0 && (
        <button
          onClick={() => setCurrentIndex(currentIndex - 1)}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 p-2 rounded-full"
        >
          ◀
        </button>
      )}
      {/* Right Arrow */}
      {currentIndex < imageList.length - 1 && (
        <button
          onClick={() => setCurrentIndex(currentIndex + 1)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-gray-800 p-2 rounded-full"
        >
          ▶
        </button>
      )}
    </div>
  </div>
);

// Modal for Consultation Form
const ConsultationModal = ({ onClose }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
      <h2 className="text-2xl font-bold mb-4">Get Consultation</h2>
      <form>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
          <input
            type="text"
            id="name"
            className="w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Your Name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
          <input
            type="email"
            id="email"
            className="w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Your Email"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
          <textarea
            id="message"
            className="w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Your Message"
            rows="4"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-[#019EDC] text-white font-semibold py-2 px-4 rounded hover:bg-[#017bb2] transition-colors duration-300"
        >
          Submit
        </button>
      </form>
      <button
        onClick={onClose}
        className="mt-4 text-red-500 hover:text-red-700 font-semibold"
      >
        Close
      </button>
    </div>
  </div>
);

const ProductItem = ({ changeProdValue }) => {
  const { id } = useParams();

  // Fetch product details using API
  const { data: productData, isLoading } = useQuery(['product-item', id], () =>
    fetchProductItem(id)
  );

  const { t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng');

  // Image display state
  const [imageValue, setImageValue] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConsultationModalOpen, setConsultationModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (productData?.data) {
      setImageValue(productData.data.image1);
    }
  }, [productData]);

  if (isLoading || !productData) {
    return (
      <div className="container mx-auto px-4 py-8">
        <p>{t('loading')}</p>
      </div>
    );
  }

  const product = productData.data;
  const productName =
    lang === 'uz'
      ? product.name_uz.split('/rn/')[0]
      : lang === 'ru'
      ? product.name_ru
      : product.name_en;

  const productDescription =
    lang === 'uz'
      ? product.description_uz
      : lang === 'ru'
      ? product.description_ru
      : product.description_en;

  const imageList = [
    product.image1,
    product.image2,
    product.image3,
    product.image4,
    product.image5,
    product.image6,
  ].filter((img) => img); // Filter out undefined images

  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Product Name */}
        <h1 className="text-3xl font-bold text-gray-800 mb-6">{productName}</h1>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Images */}
          <div className="w-full md:w-1/2 flex flex-col">
            {/* Main Image */}
            <div
              className="w-full h-80 bg-white rounded-lg border border-gray-200 flex items-center justify-center mb-4 cursor-pointer"
              onClick={() => {
                setModalOpen(true);
                setCurrentIndex(imageList.indexOf(imageValue));
              }}
            >
              <img
                src={imageValue}
                alt={productName}
                className="max-h-full object-contain"
              />
            </div>
            {/* Thumbnail Images */}
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              className="w-full"
            >
              {imageList.map((img, index) => (
                <SwiperSlide key={index}>
                  <div
                    onClick={() => setImageValue(img)}
                    className="cursor-pointer border border-gray-200 rounded-lg p-2 hover:border-[#019EDC]"
                  >
                    <img src={img} alt={`Thumbnail ${index + 1}`} className="w-full h-24 object-contain" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Right Column - Product Info */}
          <div className="w-full md:w-1/2 flex flex-col">
            {/* Price */}
            <p className="text-3xl font-semibold text-gray-800 mb-4">
              {product.price} {t('sum')}
            </p>
            {/* Short Description */}
            <p className="text-gray-600 mb-6">{t('desc_buy')}</p>

            {/* Product Characteristics with dotted lines */}
            <div className="mt-8">
              <h2 className="text-xl font-bold text-gray-800 mb-4">{t('characters')}</h2>
              <div className="space-y-2">
                {productDescription?.split('\r\n\r\n').map((desc, index) => (
                  <div key={index} className="flex justify-between text-gray-700 border-b border-dotted border-gray-300 pb-2">
                    <span>{desc.split(':')[0]}</span>
                    <span>{desc.split(':')[1]}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Buy Button */}
            <div className="mt-6 flex flex-col gap-3">
              <button
                className="bg-[#019EDC] text-white font-semibold py-3 px-6 rounded-lg hover:bg-[#017bb2] transition-colors duration-300"
                onClick={() => changeProdValue(product.name)}
              >
                {t('buynow')}
              </button>
              {/* Get Consultation Button */}
              <button
                className="bg-[#4CAF50] text-white font-semibold py-3 px-6 rounded-lg hover:bg-[#45A049] transition-colors duration-300"
                onClick={() => setConsultationModalOpen(true)}
              >
                {t('getConsultation')}
              </button>
            </div>
          </div>
        </div>

        {/* Product Description */}
        <div className="mt-12">
          <h2 className="text-xl font-bold text-gray-800 mb-4">{t('description_product')}</h2>
          <p className="text-gray-700 whitespace-pre-line">{product.text}</p>
        </div>
      </div>

      {/* Top Products Section */}
      <TopProducts changeProdValue={changeProdValue} />

      {/* Image Modal */}
      {isModalOpen && (
        <ImageModal
          imgSrc={imageList[currentIndex]}
          onClose={() => setModalOpen(false)}
          imageList={imageList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )}

      {/* Consultation Modal */}
      {isConsultationModalOpen && (
        <ConsultationModal onClose={() => setConsultationModalOpen(false)} />
      )}
    </>
  );
};

export default ProductItem;
