import React from 'react';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import { fetchSliders } from '../../api/Api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = () => {
    // API call to fetch slider data
    const dataSlider = useQuery('sliders', fetchSliders);

    // Slick Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
    };

    return (
        <div className='w-full'>
            {/* Apply padding of 10vw to left and right */}
            <div className="mx-auto px-[6vw] py-4">
                <Slider {...settings}>
                    {dataSlider?.data?.data?.map((item) => (
                        <div key={item?.id} className="rounded-lg overflow-hidden">
                            <img 
                                src={item?.image} 
                                alt={`Slide ${item?.id}`} 
                                className="w-full h-auto rounded-lg object-cover"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Header;
