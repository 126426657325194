import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { fetchCategories } from '../../api/Api';
import { getName } from '../../languages/language';
import { useTranslation } from 'react-i18next';

const Categories = () => {
  // Fetch categories using React Query
  const { data, isLoading, isError } = useQuery('categories', fetchCategories);

  // i18next for translation
  const { t } = useTranslation();

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl text-gray-500">{t('loading')}...</div>
      </div>
    );
  }

  // Show error state
  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl text-red-500">{t('errorLoadingCategories')}</div>
      </div>
    );
  }

  // Check if categories data is empty
  if (!data?.data || data.data.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl text-gray-500">{t('noCategoriesFound')}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-8">
       
      </div>

      {/* Categories Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {data?.data?.map((category) => (
          <Link
            key={category.id}
            to={`/categories/${category.id}`}
            className="group flex flex-col items-center p-6 bg-white rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300 focus:outline-none focus:ring-2 focus:ring-[#019EDC]"
          >
            {/* Category Image */}
            <div className="w-full h-32 flex items-center justify-center mb-4">
              <img
                src={category.image || '/default-category.png'} // Fallback image
                alt={getName(category)}
                className="max-h-full object-contain"
                loading="lazy" // Improves performance by lazy loading images
              />
            </div>

            {/* Category Name */}
            <p className="text-base sm:text-lg md:text-xl font-semibold text-gray-800 text-center group-hover:text-[#019EDC] transition-colors duration-300">
              {getName(category)}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Categories;
